import React, { useState, useEffect } from "react";
import "./ProductCategorie.css";
//Ant Design
import { Spin } from "antd";
//react toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//api
import {
  addProductCategories,
  getAllProductCategories,
  deleteSingeleProductCategory,
  updateSingeleProductCategory,
} from "../../../API/productcategoriesAPI";
//mui model
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
//
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
//mui grid table
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from "@mui/x-data-grid";
//
//material ui modal
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
//
//

//
//

// Define interface for warehouse object
interface Productcategorie {
  id: number;
  name: string;
  discription: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
}

const ProductCategorie = () => {
  //
  const notifyDefault = (msg: string) =>
    toast(msg || "Default!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyInfo = (msg: string) =>
    toast.info(msg || "Info!", {
      position: "top-right",
      theme: "colored",
    });
  const notifySuccess = (msg: string) =>
    toast.success(msg || "Success!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyWarning = (msg: string) =>
    toast.warn(msg || "Warning!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyError = (msg: string) =>
    toast.error(msg || "Error!", {
      position: "top-right",
      theme: "colored",
    });
  //
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  //
  const [productcategorieData, setProductCategorieData] = useState<
    Productcategorie[]
  >([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  //new ProductCategori states
  const [NewProductCategorieName, setNewProductCategorieName] = useState("");
  const [NewProductCategorieUUID, setNewProductCategorieUUID] = useState("");
  const [NewProductCategorieDiscription, setNewProductCategorieDiscription] =
    useState("");
  //edit ProductCategori states
  const [EditProductCategorieID, setEditProductCategorieID] = useState("");
  const [EditProductCategorieName, setEditProductCategorieName] = useState("");
  const [EditProductCategorieUUID, setEditProductCategorieUUID] = useState("");
  const [EditProductCategorieDiscription, setEditProductCategorieDiscription] =
    useState("");
  //new warehouse mui modal state
  const [openNewWarehouse, setOpenNewWarehouse] = React.useState(false);
  const handleOpenNewWarehouse = () => setOpenNewWarehouse(true);
  const handleCloseNewWareHouse = () => setOpenNewWarehouse(false);
  //edit warehouse mui modal state
  const [openEditWarehouse, setOpenEditWarehouse] = React.useState(false);
  const handleOpenEditWarehouse = () => setOpenEditWarehouse(true);
  const handleCloseEditWareHouse = () => setOpenEditWarehouse(false);
  //custom methods
  const HandleAddProductCategorie = async (event: any) => {
    event.preventDefault();
    try {
      //
      setLoading(true);
      //
      var req = await addProductCategories({
        Name: NewProductCategorieName,
        UUID: NewProductCategorieUUID,
        Description: NewProductCategorieDiscription,
      });
      console.log(req);
      //HandleGetProductCategories();
      notifySuccess(req.data);
    } catch (err: any) {
      notifyError(err.response.data);
    } finally {
      //
      setLoading(false);
      //
    }
  };
  //

  const HandleUpdateProductCategory = async (event: any) => {
    event.preventDefault();
    try {
      //
      // alert(
      //   `ID==${supplierID} \n Name==${supplierName} \n Email==${supplierEmail} \n Address==${supplierAddress}`
      // );
      //
      //
      setLoading(true);
      //
      const req = await updateSingeleProductCategory({
        ID: EditProductCategorieID as unknown as number,
        Name: EditProductCategorieName,
        UUID: EditProductCategorieUUID,
        Description: EditProductCategorieDiscription,
      });
      // console.log(call);
      HandleGetProductCategories();
    } catch (err: any) {
      console.log(err);
      notifyError(`${err.response.data}\n Status Code ${err.status}`);
    } finally {
      //
      setLoading(false);
      //
    }
  };
  //

  const HandleGetProductCategories = async () => {
    try {
      var req: any = await getAllProductCategories();
      setProductCategorieData(req.data);
      console.log(req.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    HandleGetProductCategories();
  }, []);
  //

  //mui
  //methods
  const handleEditClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    const editedRow: any = productcategorieData.find((row) => row.id === id);
    setEditProductCategorieID(editedRow.id);
    setEditProductCategorieName(editedRow.name);
    setEditProductCategorieUUID(editedRow.uuid);
    setEditProductCategorieDiscription(editedRow.description);
    handleOpenEditWarehouse();
  };

  const handleSaveClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // setRowModesModel({
    //   ...rowModesModel,
    //   [id]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };
  const handleDeleteClick = (id: GridRowId) => async () => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      const call = await deleteSingeleProductCategory({ id: id });
      console.log(call);
      HandleGetProductCategories();
      notifySuccess(call.data);
    } catch (err: any) {
      console.log(err);
      if (err.response.data) {
        notifyError(err.response.data);
      }
      if (err.response.data.name) {
        notifyError(err.response.data.name);
      }
    }
  };

  //
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 130 },
    { field: "uuid", headerName: "UUID", width: 130 },
    { field: "description", headerName: "Discription", width: 130 },
    {
      field: "createdBy",
      valueGetter: (value, row) => {
        return `${row.CreatedByUser ? row.CreatedByUser.fullName : ""}`;
      },
      headerName: "Created By",
      width: 130,
    },
    { field: "createdAt", headerName: "Created At", width: 150 },
    {
      field: "updatedBy",
      valueGetter: (value, row) => {
        return `${row.UpdatedByUser ? row.UpdatedByUser.fullName : ""}`;
      },
      headerName: "Updated By",
      width: 130,
    },
    { field: "updatedAt", headerName: "Updated At", width: 150 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div className="warehouse">
      <ToastContainer />
      <div className="ActionDiv">
        <button className="button" onClick={handleOpenNewWarehouse}>
          New Categories
        </button>
      </div>
      <div className="warehouseDisplay"></div>
      {/* New warehouse mode */}
      <Modal
        open={openNewWarehouse}
        onClose={handleCloseNewWareHouse}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            New Product Categorie
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Spin spinning={loading}>
              <form
                onSubmit={HandleAddProductCategorie}
                style={{ width: "100%" }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className="productlabels">
                        Name:
                        <input
                          type="text"
                          placeholder=""
                          onChange={(event) =>
                            setNewProductCategorieName(event.target.value)
                          }
                          required
                        ></input>
                      </label>

                      <label className="productlabels">
                        UUID:
                        <input
                          type="text"
                          placeholder="01"
                          onChange={(event) =>
                            setNewProductCategorieUUID(event.target.value)
                          }
                          maxLength={2}
                          required
                        ></input>
                      </label>

                      <label className="productlabels">
                        Description :
                        <textarea
                          placeholder="Product Categorie Discription"
                          onChange={(event) =>
                            setNewProductCategorieDiscription(
                              event.target.value
                            )
                          }
                        ></textarea>
                      </label>

                      {/* <label>
                        Supplier Email :{" "}
                        <input
                          type="email"
                          placeholder=""
                          onChange={(event) =>
                            setSupplierEmail(event.target.value)
                          }
                          required
                        ></input>
                      </label> */}

                      {/* <label>
                        Supplier Address :{" "}
                        <textarea
                          onChange={(event) =>
                            setSupplierAddress(event.target.value)
                          }
                          required
                        ></textarea>
                      </label> */}
                    </div>
                  </div>
                </div>

                <button className="addbtn" type="submit">
                  Add
                </button>
              </form>
            </Spin>
          </Typography>
        </Box>
      </Modal>
      {/*  */}
      {/* Edit Product Category mode */}
      <Modal
        open={openEditWarehouse}
        onClose={handleCloseEditWareHouse}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Product Categorie
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Spin spinning={loading}>
              <form
                onSubmit={HandleUpdateProductCategory}
                style={{ width: "100%" }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className="productlabels">
                        Name:
                        <input
                          type="text"
                          placeholder=""
                          value={EditProductCategorieName}
                          onChange={(event) =>
                            setEditProductCategorieName(event.target.value)
                          }
                          required
                        ></input>
                      </label>

                      <label className="productlabels">
                        UUID:
                        <input
                          type="text"
                          placeholder="01"
                          value={EditProductCategorieUUID}
                          onChange={(event) =>
                            setEditProductCategorieUUID(event.target.value)
                          }
                          maxLength={2}
                          required
                        ></input>
                      </label>

                      <label className="productlabels">
                        Description :
                        <textarea
                          placeholder="Product Categorie Discription"
                          value={EditProductCategorieDiscription}
                          onChange={(event) =>
                            setEditProductCategorieDiscription(
                              event.target.value
                            )
                          }
                        ></textarea>
                      </label>
                    </div>
                  </div>
                </div>

                <button className="addbtn" type="submit">
                  Update
                </button>
              </form>
            </Spin>
          </Typography>
        </Box>
      </Modal>
      {/*  */}
      {/* Material ui warehose table */}
      <DataGrid
        rows={productcategorieData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              id: false,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  );
};

export default ProductCategorie;
