import * as React from "react";
import "./userTable.css";
import { useLocation } from "react-router-dom";
////Access Controle Middle Wares
import {
  CheckModuleCreateAccess,
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
} from "../../../middleware/authorizationMiddleware";
//
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Ant Design
import { UserOutlined } from "@ant-design/icons";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input } from "antd";
import { Tooltip } from "antd";
import { Spin } from "antd";
import { Checkbox } from "antd";
import type { GetProp } from "antd";
//ant design icons
import { UserAddOutlined, UserSwitchOutlined } from "@ant-design/icons";
//
import { useEffect, useState, useCallback } from "react";
//
import { Authorization } from "../../../middleware/authorizationMiddleware";
//
import { sessionStorage } from "es-storage";
//ant design
//mui
import { DataGrid, GridColDef } from "@mui/x-data-grid";
//api methods
import {
  addUser,
  getAllUser,
  deleteSingeleUser,
  UpdateUserRBAC,
} from "../../../API/userAPI";
import { getAllTenantModule } from "../../../API/moduleAPI";
//
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
//
//
//
import {
  // GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  // GridToolbarContainer,
  GridActionsCellItem,
  // GridEventListener,
  GridRowId,
  // GridRowModel,
  // GridRowEditStopReasons,
  // GridSlots,
} from "@mui/x-data-grid";
import { Label, Update } from "@mui/icons-material";
import { create } from "domain";
//
//material ui modal
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
//
const rbacSessionArray: { UserModules: any } =
  sessionStorage.getObject("user_rbac");
//
const plainOptions = [
  { label: "Create", value: "create" },
  { label: "Read", value: "read" },
  { label: "Update", value: "update" },
  { label: "Delete", value: "delete" },
];

const RBACNewUserData: any[] = [];
let EditRBACUserData: any[] = [];
//

// Define interface for product object
interface User {
  id: number;
  fullName: string;
  email: string;
  phone: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  role: string;
}

// interface RBAC {
//   Employee: string;
//   Dashboard: string;
//   Discuss: string;
//   Inventory: string;
//   Customer: string;
//   Supplier: string;
// }

export default function CustomerTable() {
  //
  // const [checkedValues, setCheckedValues] = useState(
  //   rbacSessionArray.UserModules.map((option:any) => [option.Role.]) // All values are initially checked
  // );
  //
  const location = useLocation();
  const moduleid = location.state?.moduleId;
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  const notifyDefault = (msg: string) =>
    toast(msg || "Default!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyInfo = (msg: string) =>
    toast.info(msg || "Info!", {
      position: "top-right",
      theme: "colored",
    });
  const notifySuccess = (msg: string) =>
    toast.success(msg || "Success!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyWarning = (msg: string) =>
    toast.warn(msg || "Warning!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyError = (msg: string) =>
    toast.error(msg || "Error!", {
      position: "top-right",
      theme: "colored",
    });
  //
  const [TenantModuleList, setTenantModuleList] = useState<any[]>([]);
  const [permissions, setPermissions] = useState([]);
  const [editPermissions, setEditPermissions] = useState([]);
  const onChange = (moduleId: number, checkedValues: any) => {
    setPermissions((prevData: any) => {
      const updatedData = prevData.map((item: any) => {
        if (item.moduleID === moduleId) {
          return {
            ...item,
            Create: checkedValues.includes("create"),
            Read: checkedValues.includes("read"),
            Update: checkedValues.includes("update"),
            Delete: checkedValues.includes("delete"),
          };
        }
        return item;
      });

      // If moduleId is not found in the existing data, add it
      if (!prevData.some((item: any) => item.moduleID === moduleId)) {
        updatedData.push({
          moduleID: moduleId,
          Create: checkedValues.includes("Create"),
          Read: checkedValues.includes("Read"),
          Update: checkedValues.includes("Update"),
          Delete: checkedValues.includes("Delete"),
        });
      }

      console.log(
        "Updated permissions for module",
        moduleId,
        "=",
        checkedValues
      );
      console.log("RBACNewUserData", updatedData);

      return updatedData;
    });
    // console.log("Updated permissions for module", moduleId, "=", checkedValues);
    // console.log("RBACNewUserData", RBACNewUserData);
  };

  const onRBACChange = (
    moduleId: number,
    checkedValues: any,
    permissionID: any
  ) => {
    setEditPermissions((prevData: any) => {
      const updatedData = prevData.map((item: any) => {
        if (item.moduleID === moduleId) {
          return {
            ...item,
            Create: checkedValues.includes("create"),
            Read: checkedValues.includes("read"),
            Update: checkedValues.includes("update"),
            Delete: checkedValues.includes("delete"),
            ID: permissionID,
          };
        }
        return item;
      });

      // If moduleId is not found in the existing data, add it
      if (!prevData.some((item: any) => item.moduleID === moduleId)) {
        updatedData.push({
          moduleID: moduleId,
          Create: checkedValues.includes("Create"),
          Read: checkedValues.includes("Read"),
          Update: checkedValues.includes("Update"),
          Delete: checkedValues.includes("Delete"),
          ID: permissionID,
        });
      }

      // console.log(
      //   "Updated permissions for module",
      //   moduleId,
      //   "=",
      //   checkedValues
      // );
      console.log("RBACNewUserData", updatedData);

      return updatedData;
    });
    // console.log("Updated permissions for module", moduleId, "=", checkedValues);
    // console.log("RBACNewUserData", RBACNewUserData);
  };

  const onChangeRBAC = (moduleId: number, checkedValues: any) => {
    console.log("Updated permissions for module", moduleId, "=", checkedValues);
    // console.log("RBACNewUserData", RBACNewUserData);
  };
  //
  const GetTenantModules = async () => {
    try {
      var req: any = await getAllTenantModule();
      setTenantModuleList(req.data);
      console.log(req.data);

      //console.log("session=" + sessionStorage.getObject("user_rbac"));
      // console.log("session=" + sessionStorage.getObject("user_rbac").length);
    } catch (err: any) {
      console.log(err);
      //notify_Error(err.response.data);
    }
  };
  //
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  //user form stats
  const [NewUserName, setNewUserName] = useState("");
  const [NewUserEmail, setNewUserEmail] = useState("");
  const [NewUserPassword, setNewUserPassword] = useState("");
  const [ConfirmUserPassword, setConfirmUserPassword] = useState("");
  const [NewUserPhone, setNewUserPhone] = useState("");
  const [NewUserRole, setNewUserRole] = useState("");
  //new RBAC
  const [RBACDashboard, setRBACDashboard] = useState("");
  const [RBACDiscuss, setRBACDiscuss] = useState("");
  const [RBACEmployee, setRBACEmployee] = useState("");
  const [RBACInventory, setRBACInventory] = useState("");
  const [RBACCustomer, setRBACCustomer] = useState("");
  const [RBACSupplier, setRBACSupplier] = useState("");
  const [RBACSales, setRBACSales] = useState("");
  //Edit user
  const [EditUserID, setEditUserID] = useState("");
  // const [EditUserName, setEditUserName] = useState("");
  // const [EditUserEmail, setEditUserEmail] = useState("");
  // const [EditUserPassword, setEditUserPassword] = useState("");
  // const [EditUserPhone, setEditUserPhone] = useState("");
  // const [EditUserRole, setEditUserRole] = useState("");
  //editEdit RBAC
  const [EditRBACDashboard, setEditRBACDashboard] = useState("");
  const [EditRBACDiscuss, setEditRBACDiscuss] = useState("");
  const [EditRBACEmployee, setEditRBACEmployee] = useState("");
  const [EditRBACInventory, setEditRBACInventory] = useState("");
  const [EditRBACCustomer, setEditRBACCustomer] = useState("");
  const [EditRBACSupplier, setEditRBACSupplier] = useState("");
  const [EditRBACSales, setEditRBACSales] = useState("");
  // const [RBAC, setRBAC] = useState([
  //   {
  //     Employee: "",
  //     Dashboard: "",
  //     Discuss: "",
  //     Inventory: "",
  //     Customer: "",
  //     Supplier: "",
  //   },
  // ]);
  // const [rbac, setRBAC] = useState<
  //   {
  //     Employee: string;
  //     Dashboard: string;
  //     Discuss: string;
  //     Inventory: string;
  //     Customer: string;
  //     Supplier: string;
  //     Sales: string;
  //   }[]
  // >([]);
  // function handleRBACDiscuss(e) {
  //   setRBAC({
  //     ...RBAC,
  //     Employee: e.target.value,
  //   });
  // }
  //
  const [userData, setUserData] = useState<User[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  //mui m new user odal state
  const [openNewUserModel, setOpenNewUserModel] = React.useState(false);
  const handleOpenNewUserModel = () => setOpenNewUserModel(true);
  const handleCloseNewUserModel = () => setOpenNewUserModel(false);
  //mui edite user model state
  const [openEditUserModel, setOpenEditUserModel] = React.useState(false);
  const handleOpenEditUserModel = () => setOpenEditUserModel(true);
  const handleCloseEditUserModel = () => setOpenEditUserModel(false);
  //

  //mui model methods
  const handleEditClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });

    const editedRow: any = userData.find((row) => row.id === id);

    // handleOpenEditUserModel();

    setEditUserID(editedRow.id);
    // alert(editedRow.id);
    EditRBACUserData = editedRow.UserModules;
    console.log(editedRow);
    // setEditRBACDashboard(editedRow.RBACs[0].Dashboard);
    // setEditRBACDiscuss(editedRow.RBACs[0].Discuss);
    // setEditRBACEmployee(editedRow.RBACs[0].Employee);
    // setEditRBACInventory(editedRow.RBACs[0].Inventory);
    // setEditRBACCustomer(editedRow.RBACs[0].Customer);
    // setEditRBACSupplier(editedRow.RBACs[0].Supplier);
    // setEditRBACSales(editedRow.RBACs[0].Sales);
    //
    handleOpenEditUserModel();
  };

  const handleSaveClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // setRowModesModel({
    //   ...rowModesModel,
    //   [id]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };

  //custom methods
  const HandleAddUser = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    if (NewUserPassword !== ConfirmUserPassword) {
      notifyWarning("Passwords do not match!");
      setLoading(false);
      return;
    } else {
      // const RBAC_DATA = [
      //   {
      //     Dashboard: RBACDashboard,
      //     Discuss: RBACDiscuss,
      //     Employee: RBACEmployee,
      //     Inventory: RBACInventory,
      //     Customer: RBACCustomer,
      //     Supplier: RBACSupplier,
      //     Sales: RBACSales,
      //   },
      // ];
      //console.log(RBAC_DATA);
      try {
        var req = await addUser({
          Name: NewUserName,
          Email: NewUserEmail,
          Phone: NewUserPhone,
          Password: NewUserPassword,
          Role: NewUserRole,
          RBAC: permissions,
          CreatedBy: sessionStorage.get("user_name"),
        });
        console.log(req);
        GetUsers();
        // notify_success(req.data.msg);
        notifySuccess(req.data.msg);
      } catch (err: any) {
        // notify_error(err.response.data);
        notifyError(err.response.data);
        console.log(err);
      } finally {
        setLoading(false); // Set loading to false after request completion or error
      }
    }
  };
  //

  //
  const HandleUpdateUserRBAC = async (event: any) => {
    event.preventDefault();

    try {
      if (!EditUserID) {
        throw new Error("Invalid User Identifier");
      }
      var req = await UpdateUserRBAC({
        UserId: EditUserID,
        // Employee: EditRBACEmployee,
        // Dashboard: EditRBACDashboard,
        // Discuss: EditRBACDiscuss,
        // Inventory: EditRBACInventory,
        // Customer: EditRBACCustomer,
        // Supplier: EditRBACSupplier,
        // Sales: EditRBACSales,
        RBAC: editPermissions,
        UpdatedBy: sessionStorage.get("user_name"),
      });
      console.log(req);
      GetUsers();
      // notify_success(req.data.msg);
      notifySuccess(req.data.msg);
    } catch (err: any) {
      // notify_error(err.response.data);
      notifyError(err.response.data);
      console.log(err);
    }
  };
  //

  //notification Methods
  // const notify_error = (message: any) =>
  //   toast.error(`error! \n ${message}`, {
  //     position: "top-center",
  //     theme: "dark",
  //   });
  // const notify_success = (message: any) =>
  //   toast.success(`Message: \t ${message}`, {
  //     position: "top-center",
  //     theme: "colored",
  //   });
  //

  const GetUsers = useCallback(async () => {
    try {
      var req: any = await getAllUser();
      setUserData(req.data);
    } catch (err) {
      console.log(err);
    }
  }, []); // No dependencies needed for useCallback as it's not dependent on any external variables

  useEffect(() => {
    GetUsers();
  }, [GetUsers]); // Dependency is now the memoized GetUsers function, which won't change on re-renders

  // mui grid Methods
  const handleDeleteClick = (id: GridRowId) => async () => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      const call = await deleteSingeleUser({ id: id });
      console.log(call);
      GetUsers();
      // notify_success(call.data);
      notifySuccess(call.data);
    } catch (err: any) {
      console.log(err);
      //notifyErr(err.response.data);
    }
  };
  //
  //
  //
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "fullName", headerName: "Name", width: 130 },
    { field: "email", headerName: "Email", width: 130 },
    { field: "phone", headerName: "Phone", width: 130 },
    { field: "createdBy", headerName: "Created By", width: 130 },
    { field: "createdAt", headerName: "Created At", width: 130 },
    { field: "updatedAt", headerName: "Updated At", width: 130 },
    { field: "role", headerName: "Role", width: 130 },
    { field: "last_login", headerName: "Last Login", width: 130 },
    //
    {
      field: "rbac_Customer",
      valueGetter: (value, row) => {
        return `${row.RBACs[0] ? row.RBACs[0].Customer : ""}`;
      },
      headerName: "RBAC Customer",
      width: 100,
    },
    {
      field: "rbac_Dashboard",
      valueGetter: (value, row) => {
        return `${row.RBACs[0] ? row.RBACs[0].Dashboard : ""}`;
      },
      headerName: "RBAC Dashboard",
      width: 100,
    },
    {
      field: "rbac_Discuss",
      valueGetter: (value, row) => {
        return `${row.RBACs[0] ? row.RBACs[0].Discuss : ""}`;
      },
      headerName: "RBAC Discuss",
      width: 100,
    },
    {
      field: "rbac_Employee",
      valueGetter: (value, row) => {
        return `${row.RBACs[0] ? row.RBACs[0].Employee : ""}`;
      },
      headerName: "RBAC Employee",
      width: 100,
    },
    {
      field: "rbac_Inventory",
      valueGetter: (value, row) => {
        return `${row.RBACs[0] ? row.RBACs[0].Inventory : ""}`;
      },
      headerName: "RBAC Inventory",
      width: 100,
    },
    {
      field: "rbac_Supplier",
      valueGetter: (value, row) => {
        return `${row.RBACs[0] ? row.RBACs[0].Supplier : ""}`;
      },
      headerName: "RBAC Supplier",
      width: 100,
    },
    {
      field: "rbac_Sales",
      valueGetter: (value, row) => {
        return `${row.RBACs[0] ? row.RBACs[0].Sales : ""}`;
      },
      headerName: "RBAC Sales",
      width: 100,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          CheckModuleEditAccess(moduleid) ? (
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />
          ) : (
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              // onClick={handleEditClick(id)}
              sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
            />
          ),

          CheckModuleEditAccess(moduleid) ? (
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />
          ) : (
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              className="textPrimary"
              sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
            />
          ),
        ];
      },
    },
  ];

  // /////////////////////////////////////////////////
  function getSelectedValues(permissions: any[]) {
    console.log(permissions);
    console.log(
      permissions.map((p: any) => [
        p.create == 1 ? "create" : null,
        p.read == 1 ? "read" : null,
        p.update == 1 ? "update" : null,
        p.delete == 1 ? "delete" : null,
      ])
    );
    return permissions.map((p: any) => [
      p.create == 1 ? "create" : "",
      p.read == 1 ? "read" : "",
      p.update == 1 ? "update" : "",
      p.delete == 1 ? "delete" : "",
    ]);
  }
  return (
    <div>
      <ToastContainer />
      {/* User sub menu actions */}
      <div className="userMngTableDiv">
        {CheckModuleCreateAccess(moduleid) ? (
          <Button
            className="newBtn"
            onClick={() => {
              GetTenantModules();
              handleOpenNewUserModel();
            }}
          >
            New User
          </Button>
        ) : // <div className="AppCard">Request The Admin for Log Modul</div>
        null}

        {/* <div>
          <label>Filter</label>
          <label>Group</label>
          <label>Favorite</label>
        </div> */}
      </div>
      {/* mui new user model */}
      <div>
        <Modal
          open={openNewUserModel}
          onClose={handleCloseNewUserModel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="NewUserModalHeaderDiv">
                <UserAddOutlined />
                New User
              </div>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Spin spinning={loading}>
                <form className="NewUserForm" onSubmit={HandleAddUser}>
                  <div className="NewUserModalDiv">
                    {/* <div className="NewUserFormContainer"> */}
                    {/* <div className="NewUserFormContainer"> */}
                    <div className="newusermodalLeft">
                      <label className="userlabels">
                        Name:
                        <input
                          type="text"
                          placeholder="Full Name"
                          onChange={(event) =>
                            setNewUserName(event.target.value)
                          }
                          minLength={2}
                          maxLength={50}
                          required
                        ></input>
                      </label>

                      <label className="userlabels">
                        Email
                        <input
                          type="email"
                          placeholder="example@example.com"
                          onChange={(event) =>
                            setNewUserEmail(event.target.value)
                          }
                          minLength={2}
                          maxLength={125}
                          required
                        ></input>
                        <p
                          style={{
                            width: "250px",
                            fontSize: "14px",
                            color: "#555",
                          }}
                        >
                          Using a proper email address allows users to receive
                          notifications.
                        </p>
                      </label>

                      <label className="userlabels">
                        Work Phone
                        <input
                          type="tel"
                          placeholder="+251-00-00-00-00"
                          onChange={(event) =>
                            setNewUserPhone(event.target.value)
                          }
                          minLength={10}
                          maxLength={15}
                        ></input>
                      </label>

                      <label className="userlabels">
                        New Password
                        {/* <input
                        type="text"
                        placeholder=""
                        onChange={(event) =>
                          setNewUserPassword(event.target.value)
                        }
                        required
                      ></input> */}
                        <Input.Password
                          placeholder="input password"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                          onChange={(event) =>
                            setNewUserPassword(event.target.value)
                          }
                          minLength={8}
                          maxLength={128}
                          required
                        />
                      </label>

                      <label className="userlabels">
                        Confirm Password
                        <Input.Password
                          placeholder="input password"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                          onChange={(event) =>
                            setConfirmUserPassword(event.target.value)
                          }
                          minLength={8}
                          maxLength={128}
                          required
                        />
                      </label>

                      <label className="userlabels">
                        Role:
                        <select
                          name="selectedRole"
                          onChange={(event) =>
                            setNewUserRole(event.target.value)
                          }
                          required
                        >
                          <option value=""></option>
                          {/* <option value="Administrator">Administrator</option> */}
                          <option value="Internal_User">Internal User</option>
                        </select>
                      </label>
                    </div>
                    <div className="newusermodalRight">
                      <h3>User Role Based Access Control</h3>
                      <div className="UserModuleRBAC">
                        {TenantModuleList.map((value: any) => (
                          <div key={value.id}>
                            <h5>{value.Module.name}</h5>
                            <>
                              <Checkbox.Group
                                options={plainOptions}
                                onChange={(checkedValues) =>
                                  onChange(value.Module.id, checkedValues)
                                }
                              />
                            </>
                          </div>
                        ))}
                        <div>
                          <h4 style={{ color: "orange" }}>
                            Understanding The System Role Based Access
                            Controle(RBAC)
                          </h4>
                          <p>
                            <strong>Read Permission:</strong> Users must have
                            read access to view and interact with a module.
                            Without this permission, users cannot access the
                            module at all.
                          </p>
                          <p>
                            <strong>Create Permission:</strong> Users with
                            create access can add new records to the module.
                            This permission alone does not allow viewing or
                            modifying existing records.
                          </p>
                          <p>
                            <strong>Update Permission:</strong> Users can modify
                            existing records within the module if they have
                            update access. This includes editing or updating
                            data.
                          </p>
                          <p>
                            <strong>Delete Permission:</strong> Users with
                            delete access can remove records from the module.
                            This action is permanent and should be used
                            cautiously.
                          </p>
                          <p>
                            <strong>Note:</strong> Changes to permissions will
                            take effect the next time the user logs in. Ensure
                            that the correct permissions are set to avoid
                            unintended access issues.
                          </p>
                          <p style={{ color: "red" }}>
                            <strong>Important:</strong> This RBAC feature is
                            currently under development. If you need to update a
                            user's RBAC settings urgently or have any questions,
                            please contact me directly.
                          </p>
                        </div>
                        {/* <label>
                          Disscuss:
                          <select
                            defaultValue={RBACDiscuss}
                            onChange={(e) => {
                              setRBACDiscuss(e.target.value);
                            }}
                            required
                          >
                            <option value="">Select Role</option>
                            <option value="Administrator">Administrator</option>
                            <option value="Moderator">Moderator</option>
                            <option value="Participant">Participant</option>
                            <option value="Observer">Observer</option>
                            <option value="AccessDenied">Access Denied</option>
                          </select>
                        </label> */}
                        {/* <label>
                          Dashboard:
                          <select
                            defaultValue={RBACDashboard}
                            onChange={(e) => {
                              setRBACDashboard(e.target.value);
                            }}
                            required
                          >
                            <option value="">Select Role</option>
                            <option value="Administrator">Administrator</option>
                            <option value="Viewer">Viewer</option>
                            <option value="AccessDenied">Access Denied</option>
                          </select>
                        </label> */}
                        {/* <label>
                          Employees:
                          <select
                            defaultValue={RBACEmployee}
                            onChange={(e) => {
                              setRBACEmployee(e.target.value);
                            }}
                            required
                          >
                            <option value="">Select Role</option>
                            <option value="Administrator">Administrator</option>
                            <option value="Manager">Manager</option>
                            <option value="Employee">Employee</option>
                            <option value="AccessDenied">Access Denied</option>
                          </select>
                        </label> */}
                        {/* <label>
                          Inventory:
                          <select
                            defaultValue={RBACInventory}
                            onChange={(e) => {
                              setRBACInventory(e.target.value);
                            }}
                            required
                          >
                            <option value="">Select Role</option>
                            <option value="Administrator">Administrator</option>
                            <option value="HR">Human Resouce</option>
                            <option value="IM">Invenory Management</option>
                            <option value="Sales">Sales</option>
                            <option value="AccessDenied">Access Denied</option>
                          </select>
                        </label> */}
                        {/* <label>
                          Customers:
                          <select
                            defaultValue={RBACCustomer}
                            onChange={(e) => {
                              setRBACCustomer(e.target.value);
                            }}
                            required
                          >
                            <option value="">Select Role</option>
                            <option value="Administrator">Administrator</option>
                            <option value="AccessDenied">Access Denied</option>
                          </select>
                        </label> */}
                        {/* <label>
                          Suppliers:
                          <select
                            defaultValue={RBACSupplier}
                            onChange={(e) => {
                              setRBACSupplier(e.target.value);
                            }}
                            required
                          >
                            <option value="">Select Role</option>
                            <option value="Administrator">Administrator</option>
                            <option value="AccessDenied">Access Denied</option>
                          </select>
                        </label> */}
                        {/* <label>
                          Sales:
                          <select
                            defaultValue={RBACSales}
                            onChange={(e) => {
                              setRBACSales(e.target.value);
                            }}
                            required
                          >
                            <option value="">Select Role</option>
                            <option value="Administrator">Administrator</option>
                            <option value="AccessDenied">Access Denied</option>
                          </select>
                        </label> */}
                      </div>
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                  <div className="NewUserModalFooterDiv">
                    <button className="AddBtn" type="submit">
                      Add
                    </button>
                  </div>
                </form>
              </Spin>
            </Typography>
          </Box>
        </Modal>
      </div>
      <div className="EditUserModal">
        <Modal
          open={openEditUserModel}
          onClose={handleCloseEditUserModel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="NewUserModalHeaderDiv">
                <UserSwitchOutlined />
                Edit User Form
              </div>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <form className="NewUserForm" onSubmit={HandleUpdateUserRBAC}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {/* <label className="userlabels">
                        Name:
                        <input
                          type="text"
                          placeholder=""
                          onChange={(event) =>
                            setNewUserName(event.target.value)
                          }
                          required
                        ></input>
                      </label> */}

                      {/* <label className="userlabels">
                        Email
                        <input
                          type="email"
                          placeholder=""
                          onChange={(event) =>
                            setNewUserEmail(event.target.value)
                          }
                          required
                        ></input>
                      </label> */}

                      {/* <label className="userlabels">
                        Work Phone
                        <input
                          type="tel"
                          placeholder=""
                          onChange={(event) =>
                            setNewUserPhone(event.target.value)
                          }
                        ></input>
                      </label> */}
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {/* <label className="userlabels">
                      New Password
                      <input
                        type="text"
                        placeholder=""
                        onChange={(event) =>
                          setNewUserPassword(event.target.value)
                        }
                        required
                      ></input>
                    </label> */}

                    {/* <label className="userlabels">
                      Role:
                      <select
                        name="selectedRole"
                        onChange={(event) => setNewUserRole(event.target.value)}
                      >
                        <option value=""></option>
                        <option value="Admin">Admin</option>
                        <option value="User">User</option>
                        <option value="RD">Research & Development</option>
                        <option value="WS">Work Shop</option>
                        <option value="HR">Human Resouce</option>
                        <option value="IM">Invenory Management</option>
                        <option value="Sales">Sales</option>
                      </select>
                    </label> */}
                    <div>
                      <h3>User Role Based Access Control</h3>
                      <div className="UserModuleRBAC">
                        {EditRBACUserData.map((value: any) => (
                          <div key={value.id}>
                            <h5>{value.Module.name}</h5>
                            <>
                              <Checkbox.Group
                                options={plainOptions}
                                defaultValue={
                                  value.Role.Permissions[0]
                                    ? Object.keys(
                                        value.Role.Permissions[0]
                                      ).filter(
                                        (key) => value.Role.Permissions[0][key]
                                      )
                                    : []
                                }
                                onChange={(checkedValues) =>
                                  onRBACChange(
                                    value.Module.id,
                                    checkedValues,
                                    value.Role.Permissions[0].id
                                  )
                                }
                              />
                            </>
                          </div>
                        ))}
                        {/* <label>
                          Disscuss:
                          <select
                            multiple={false}
                            value={EditRBACDiscuss}
                            onChange={(e) => {
                              setEditRBACDiscuss(e.target.value);
                            }}
                          >
                            <option value="">Select Role</option>
                            <option value="Administrator">Administrator</option>
                            <option value="Moderator">Moderator</option>
                            <option value="Participant">Participant</option>
                            <option value="Observer">Observer</option>
                            <option value="AccessDenied">Access Denied</option>
                          </select>
                        </label>
                        <label>
                          Dashboard:
                          <select
                            value={EditRBACDashboard}
                            onChange={(e) => {
                              setEditRBACDashboard(e.target.value);
                            }}
                          >
                            <option value="">Select Role</option>
                            <option value="Administrator">Administrator</option>
                            <option value="AccessDenied">Access Denied</option>
                          </select>
                        </label>
                        <label>
                          Employees:
                          <select
                            value={EditRBACEmployee}
                            onChange={(e) => {
                              setEditRBACEmployee(e.target.value);
                            }}
                          >
                            <option value="">Select Role</option>
                            <option value="Administrator">Administrator</option>
                            <option value="Manager">Manager</option>
                            <option value="Employee">Employee</option>
                            <option value="AccessDenied">Access Denied</option>
                          </select>
                        </label>
                        <label>
                          Inventory:
                          <select
                            value={EditRBACInventory}
                            onChange={(e) => {
                              setEditRBACInventory(e.target.value);
                            }}
                          >
                            <option value="">Select Role</option>
                            <option value="Administrator">Administrator</option>
                            <option value="HR">Human Resouce</option>
                            <option value="IM">Invenory Management</option>
                            <option value="Sales">Sales</option>
                            <option value="AccessDenied">Access Denied</option>
                          </select>
                        </label>
                        <label>
                          Customers:
                          <select
                            value={EditRBACCustomer}
                            onChange={(e) => {
                              setEditRBACCustomer(e.target.value);
                            }}
                          >
                            <option value="">Select Role</option>
                            <option value="Administrator">Administrator</option>
                            <option value="AccessDenied">Access Denied</option>
                          </select>
                        </label>
                        <label>
                          Suppliers:
                          <select
                            value={EditRBACSupplier}
                            onChange={(e) => {
                              setEditRBACSupplier(e.target.value);
                            }}
                          >
                            <option value="">Select Role</option>
                            <option value="Administrator">Administrator</option>
                            <option value="AccessDenied">Access Denied</option>
                          </select>
                        </label>

                        <label>
                          Sales:
                          <select
                            value={EditRBACSales}
                            onChange={(e) => {
                              setEditRBACSales(e.target.value);
                            }}
                          >
                            <option value="">Select Role</option>
                            <option value="Administrator">Administrator</option>
                            <option value="SalesManager">Sales Manager</option>
                            <option value="SalesRepresentativ">
                              Sales Representativ
                            </option>
                            <option value="AccessDenied">Access Denied</option>
                          </select>
                        </label> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="NewUserModalFooterDiv">
                  <button className="AddBtn" type="submit">
                    Update
                  </button>
                  {/* <h4>Under development</h4> */}
                </div>
              </form>
            </Typography>
          </Box>
        </Modal>
      </div>
      {/*  */}
      <DataGrid
        className="UserMngDataGrid"
        rows={userData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              rbac_Customer: false,
              rbac_Dashboard: false,
              // rbac_Discuss: false,
              rbac_Employee: false,
              rbac_Inventory: false,
              rbac_Supplier: false,
              rbac_Sales: false,
              // actions: Authorization(
              //   "Employee",
              //   sessionStorage.get("rbac_Employee"),
              //   ["Administrator", "Manager"]
              // )
              //   ? true
              //   : false,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  );
}
