import React, { useState, useEffect } from "react";
import "./Inventory.css";
//
import { useLocation } from "react-router-dom";
////Access Controle Middle Wares
import {
  CheckUserModuleAccess,
  CheckModuleCreateAccess,
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
} from "../../middleware/authorizationMiddleware";
//
//ant design
import {
  DashboardOutlined,
  DollarOutlined,
  ToolOutlined,
  ControlOutlined,
  ProductOutlined,
  HomeOutlined,
} from "@ant-design/icons";
//
import { sessionStorage } from "es-storage";
//
import { Authorization } from "../../middleware/authorizationMiddleware";
//
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//import { Outlet, Link } from "react-router-dom";
//pages
import Dashboard from "./Dashboard/Dashboard";
import WarehouseMng from "./WarehouseMng/WarehouseMng";
import ProductCategorie from "./ProductCategory/ProductCategorie";
import ProductSubCategorie from "./ProductSubCategory/ProductSubCategorie";
import NONFIXED from "./NonFixed/NonFixed";
import FIXED from "./Fixed/Fixed";
import ProductEditApproval from "./ProductEditApproval/ProductEditApproval";
//
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import ProductTable from "./Tables/productTable";
//api controllers
// import { addProduct } from "../../API/productAPI";
// import { getAllSupplier } from "../../API/supplierAPI";
//mui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
//mui for menu
//import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Transfer from "./Transfer/Transfer";
//
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Inventory = () => {
  //
  const location = useLocation();
  const moduleid = location.state?.moduleId;
  console.log(moduleid);
  //
  const navigate = useNavigate();
  //
  const userRole = sessionStorage.get("user_role");
  const [isAdmin, setIsAdmin] = useState(false);
  //

  useEffect(() => {
    if (userRole == "Admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);
  //
  const notifyError = (message: any) =>
    toast.error(`error! \n ${message}`, {
      position: "top-center",
      theme: "dark",
    });
  const notify_success = (message: any) =>
    toast.success(`Message: \n ${message}`, {
      position: "top-center",
      theme: "colored",
    });
  //
  //states
  // const [ProductName, setProductName] = useState("");
  // const [ProductImage, setProductImage] = useState("");
  // const [ProductQuantity, setProductQuantity] = useState("");
  // const [ProductPrice, setProductPrice] = useState("");
  // const [ProductSupplierID, setProductSupplierID] = useState("");
  // const [ProductSupplierA, setProductSupplierA] = useState([]);
  // const ProductSupplier: any = [];
  //Methods

  // const handleAddProduct = async (event: any) => {
  //   event.preventDefault();

  //   try {
  //     //set suppliers
  //     //
  //     var req = await addProduct({
  //       Name: ProductName,
  //       Image: ProductImage,
  //       Quantity: ProductQuantity,
  //       Sales_Price: ProductPrice,
  //       Supplier_ID: ProductSupplierID,
  //     });
  //     console.log(req);
  //     notify_success(req.data);
  //   } catch (err: any) {
  //     console.log(err);
  //     notifyError(err.response.data.message);
  //   }
  // };

  // const handleImageChange = (e: any) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       // Set the base64 string as the source of the image
  //       setProductImage(reader.result as string);
  //     };
  //     // Read the file as a data URL
  //     reader.readAsDataURL(file);
  //   }
  // };
  //

  //mui  menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //navigate("configuration");
    setAnchorEl(event.currentTarget);
    //navigate("configuration");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //
  return (
    <div className=".moduleMainDiv">
      <ToastContainer />
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h2 className="moduleName">Inventory</h2>
        </div>
      </div>

      <div className="moduleMngActionDiv">
        {isAdmin ? (
          <Button
            onClick={(event) => {
              // handleOpen();
              // HandleGetSuppliers(event);
              navigate("./");
            }}
          >
            Over View
          </Button>
        ) : // <div className="AppCard">Request The Admin for Log Modul</div>
        null}

        {/* {isAdmin ? (
            <Button
              onClick={(event) => {
                // handleOpen();
                // HandleGetSuppliers(event);
                navigate("product");
              }}
            >
              Product
            </Button>
          ) : // <div className="AppCard">Request The Admin for Log Modul</div>
          null} */}

        {CheckUserModuleAccess(moduleid) ? (
          <Button
            onClick={(event) => {
              // handleOpen();
              // HandleGetSuppliers(event);
              navigate("./");
            }}
          >
            <DashboardOutlined /> Dashboard
          </Button>
        ) : // <div className="AppCard">Request The Admin for Log Modul</div>
        null}

        {CheckUserModuleAccess(moduleid) ? (
          <Button
            onClick={(event) => {
              // handleOpen();
              // HandleGetSuppliers(event);
              navigate("product", {
                state: {
                  moduleId: moduleid,
                },
              });
            }}
          >
            <DollarOutlined /> Products for Sale
          </Button>
        ) : // <div className="AppCard">Request The Admin for Log Modul</div>
        null}

        {/* {Authorization("Inventory", sessionStorage.get("rbac_Inventory"), [
            "Administrator",
          ]) ? (
            <Button
              onClick={(event) => {
                // handleOpen();
                // HandleGetSuppliers(event);
                navigate("nonfixed");
              }}
            >
              <ProductOutlined /> Non-Fixed
            </Button>
          ) : null} */}

        {/* 
          {Authorization("Inventory", sessionStorage.get("rbac_Inventory"), [
            "Administrator",
          ]) ? (
            <Button
              onClick={(event) => {
                // handleOpen();
                // HandleGetSuppliers(event);
                navigate("fixed");
              }}
            >
              <ToolOutlined /> Fixed
            </Button>
          ) : null} */}

        {/* {isAdmin ? (
            <Button
              onClick={(event) => {
                // handleOpen();
                // HandleGetSuppliers(event);
                navigate("product");
              }}
            >
              Reporting
            </Button>
          ) : // <div className="AppCard">Request The Admin for Log Modul</div>
          null} */}

        {CheckUserModuleAccess(moduleid) ? (
          // <Button
          //   onClick={(event) => {
          //     // handleOpen();
          //     // HandleGetSuppliers(event);
          //     navigate("configuration");
          //   }}
          // >
          //   Configurations
          // </Button>
          <div>
            {CheckUserModuleAccess(moduleid) ? (
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <ControlOutlined />
                Configurations
              </Button>
            ) : // <div className="AppCard">Request The Admin for Log Modul</div>
            null}

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>
                <label
                  onClick={(event) => {
                    navigate("warehousemng");
                  }}
                >
                  <HomeOutlined />
                  Warehouse Management
                </label>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <label
                  onClick={(event) => {
                    navigate("productcategory");
                  }}
                >
                  Product Categories
                </label>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <label
                  onClick={(event) => {
                    navigate("productsubcategory");
                  }}
                >
                  Sub Categories
                </label>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <label
                  onClick={(event) => {
                    navigate("producttransfer");
                  }}
                >
                  Transfer
                </label>
              </MenuItem>
              {/* <MenuItem onClick={handleClose}>
                  <label
                    onClick={(event) => {
                      navigate("productsubcategory");
                    }}
                  >
                    Replenishment
                  </label>
                </MenuItem> */}
            </Menu>
          </div>
        ) : // <div className="AppCard">Request The Admin for Log Modul</div>
        null}
      </div>

      <div className="moduleDisplay">
        <Routes>
          <Route index element={<Dashboard />}></Route>
          <Route path="/product/*" element={<ProductTable />}></Route>
          <Route path="/nonfixed" element={<NONFIXED />} />
          <Route path="/fixed" element={<FIXED />} />
          <Route path="/warehousemng" element={<WarehouseMng />} />
          <Route path="/productcategory" element={<ProductCategorie />} />
          <Route path="/productsubcategory" element={<ProductSubCategorie />} />
          <Route path="/producttransfer" element={<Transfer />} />
        </Routes>
      </div>
    </div>
  );
};

export default Inventory;
