import React, { useState } from "react";
import "./CustomerMng.css";
//
////Access Controle Middle Wares
import {
  CheckModuleCreateAccess,
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
} from "../../middleware/authorizationMiddleware";
//
//
import { useLocation } from "react-router-dom";
//api methods
import { addCustomer } from "../../API/customerAPI";
//
import CustomerTable from "./Tables/customerTable";
//notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//ant design
import { Spin } from "antd";
//mui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

//

const CustomerMng = () => {
  const location = useLocation();
  const moduleid = location.state?.moduleId;
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //mui modal state
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //user form stats
  const [customerName, setCustomerName] = useState("");
  const [customerMobile, setCustomerMobile] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  //notification Methods
  const notify = (message: any) =>
    toast.error(`error! \n ${message}`, {
      position: "top-center",
      theme: "dark",
    });
  const notify_success = (message: any) =>
    toast.success(`Message: \n ${message}`, {
      position: "top-center",
      theme: "colored",
    });
  //
  //methods
  const HandleAddCustomer = async (event: any) => {
    event.preventDefault();
    try {
      //
      setLoading(true);
      //
      var req = await addCustomer({
        Name: customerName,
        Mobile: customerMobile,
        Email: customerEmail,
        Address: customerAddress,
      });
      console.log(req);
      notify_success(req.data.msg);
    } catch (err: any) {
      notify(err.response.data);
      console.log(err);
    } finally {
      //
      setLoading(false);
      //
    }
  };
  //
  return (
    <div className="moduleMainDiv">
      <ToastContainer />
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h2 className="moduleName">Customer</h2>
        </div>
        {/* <div>
          <label>Dashboard</label>
          <label>Product</label>
          <label>Configeration</label>
        </div> */}
      </div>
      <div className="moduleMngActionDiv">
        {CheckModuleCreateAccess(moduleid) ? (
          <button className="moduleCreateBtn" onClick={handleOpen}>New</button> // <div className="AppCard">Request The Admin for Log Modul</div>
        ) : null}
        {/* <div>
          <label>Filter</label>
          <label>Group</label>
          <label>Favorite</label>
        </div> */}
      </div>
      <div className="moduleDisplay">
        <CustomerTable ModuleID={moduleid} />
      </div>
      {/* mui model */}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="ProductEditModalHeaderDiv">New Customer Form</div>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Spin spinning={loading}>
                <form onSubmit={HandleAddCustomer} style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label className="productlabels">
                          Customer Name
                          <input
                            type="text"
                            placeholder="Name"
                            onChange={(event) =>
                              setCustomerName(event.target.value)
                            }
                            required
                          ></input>
                        </label>

                        <label className="productlabels">
                          Customer Mobile
                          <input
                            type="tel"
                            placeholder=""
                            onChange={(event) =>
                              setCustomerMobile(event.target.value)
                            }
                          ></input>
                        </label>

                        <label className="productlabels">
                          Customer Email
                          <input
                            type="email"
                            placeholder=""
                            onChange={(event) =>
                              setCustomerEmail(event.target.value)
                            }
                          ></input>
                        </label>

                        <label className="productlabels">
                          Customer Address
                          <textarea
                            onChange={(event) =>
                              setCustomerAddress(event.target.value)
                            }
                          ></textarea>
                        </label>
                      </div>
                    </div>
                  </div>
                  <button className="addbtn" type="submit">
                    Add
                  </button>
                </form>
              </Spin>
            </Typography>
          </Box>
        </Modal>
      </div>
      {/*  */}
    </div>
  );
};

export default CustomerMng;
