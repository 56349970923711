import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");
const current_id = sessionStorage.get("user_id");

const addProduct = async (props: any) => {
  try {
    //
    const formData = new FormData();
    // formData.append("name", props.Name);
    // formData.append("internalref", props.InternalRef);
    // formData.append("model", props.Model);
    // formData.append("brand_name", props.Brand_Name);
    // formData.append("purchase_method", props.Purchase_Method);
    // formData.append("document", props.Document);
    // formData.append("description", props.Description);
    formData.append("quantity", props.Quantity);
    formData.append("cost_price", props.Cost_Price);
    formData.append("sales_price", props.Sales_Price);
    formData.append("supplier_id", props.Supplier_ID);
    formData.append("warehouse_id", props.Warehouse_ID);
    formData.append("productCategorie_id", props.ProductCategorie_ID);
    formData.append("productSubCategorie_id", props.ProductSubCategorie_ID);
    formData.append("CreatedBy", current_id);
    // Append the image if it exists
    if (props.Image) {
      formData.append("image", props.Image);
    }
    //
    var req = await axios.post(
      `${process.env.REACT_APP_PRODUCT_ADD_ONE}`,
      {
        name: props.Name,
        internalref: props.InternalRef,
        model: props.Model,
        brand_name: props.Brand_Name,
        purchase_method: props.Purchase_Method,
        document: props.Document,
        description: props.Description,
        quantity: props.Quantity,
        cost_price: props.Cost_Price,
        sales_price: props.Sales_Price,
        supplier_id: props.Supplier_ID,
        warehouse_id: props.Warehouse_ID,
        productCategorie_id: props.ProductCategorie_ID,
        productSubCategorie_id: props.ProductSubCategorie_ID,
        image: props.Image,
        CreatedBy: current_id,
      },
      {
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      // alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

const getAllProduct = async () => {
  try {
    var req = await axios.get(`${process.env.REACT_APP_PRODUCT_GET_ALL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    //console.log("Products :" + JSON.stringify(req, null, 2));
    return req;
  } catch (err: any) {
    console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
  }
};

const updateSingeleProduct = async (props: any) => {
  // alert(props.id)
  try {
    // alert(
    //   `ID==${props.ID} \n Name==${props.Name} \n Email==${props.Email} \n Address==${props.Address}`
    // );
    var req = await axios.put(
      `${process.env.REACT_APP_PRODUCT_UPDATE_ONE}/${props.ID}`,
      {
        internalref: props.InternalRef,
        name: props.Name,
        model: props.Model,
        brand_name: props.Brand_Name,
        document: props.Document,
        description: props.Description,
        image: props.Image,
        quantity: props.Quantity,
        cost_price: props.Cost_Price,
        sales_price: props.Sales_Price,
        supplier_id: props.Supplier_ID,
        purchase_method: props.Purchase_Method,
        productCategorie_id: props.ProductCategorie_ID,
        productSubCategorie_id: props.ProductSubCategorie_ID,
        warehouse_id: props.Warehouse_ID,
        UpdatedBy: current_user,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

const SaveUpdateSingeleProductRequest = async (props: any) => {
  // alert(props.id)
  try {
    // alert(
    //   `ID==${props.ID} \n Name==${props.Name} \n Email==${props.Email} \n Address==${props.Address}`
    // );
    var req = await axios.put(
      `${process.env.REACT_APP_SAVE_PRODUCT_UPDATE_ONE_REQUEST}/${props.ID}`,
      {
        internalref: props.InternalRef,
        name: props.Name,
        model: props.Model,
        brand_name: props.Brand_Name,
        document: props.Document,
        description: props.Description,
        image: props.Image,
        quantity: props.Quantity,
        cost_price: props.Cost_Price,
        sales_price: props.Sales_Price,
        supplier_id: props.Supplier_ID,
        purchase_method: props.Purchase_Method,
        productCategorie_id: props.ProductCategorie_ID,
        productSubCategorie_id: props.ProductSubCategorie_ID,
        warehouse_id: props.Warehouse_ID,
        UpdatedBy: current_user,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

const getAllProductEditRequests = async () => {
  try {
    var req = await axios.get(`${process.env.REACT_APP_PRODUCT_Edit_Request_GET_ALL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    //console.log("Products :" + JSON.stringify(req, null, 2));
    return req;
  } catch (err: any) {
    console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
  }
};

const deleteSingeleProduct = async (props: any) => {
  // alert(props.id)
  try {
    var req = await axios.delete(
      `${process.env.REACT_APP_PRODUCT_DELETE_ONE}/${props.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

export {
  addProduct,
  getAllProduct,
  updateSingeleProduct,
  SaveUpdateSingeleProductRequest,
  getAllProductEditRequests,
  deleteSingeleProduct,
};
