import React, { useState, useEffect } from "react";
import "./UserMng.css";
//
import Department from "./Department/Department";
import UsersConfiguration from "./UsersConfiguration/UsersConfiguration";
//
import { sessionStorage } from "es-storage";
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
//import { Outlet, Link } from "react-router-dom";
//
//api methods
//import { addUser } from "../../API/userAPI";
//auth middleware
import { CheckModuleCreateAccess } from "../../middleware/authorizationMiddleware";
//
import UserTable from "./Tables/userTable";
//notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
//mui
//import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import Modal from "@mui/material/Modal";
//

// const style = {
//   position: "absolute" as "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

//

const UserMng = () => {
  //
  const navigate = useNavigate();
  //
  const location = useLocation();
  const moduleId = location.state?.moduleId; // Extract module ID from state
  //
  const userRole = sessionStorage.get("user_role");
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (userRole === "Admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [userRole]);
  //
  //mui modal state
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  //user form stats
  // const [userName, setUserName] = useState("");
  // const [userEmail, setUserEmail] = useState("");
  // const [userPassword, setUserPassword] = useState("");
  // const [NewUserRole, setUserRole] = useState("");
  //notification Methods
  // const notify = (message: any) =>
  //   toast.error(`error! \n ${message}`, {
  //     position: "top-center",
  //     theme: "dark",
  //   });
  // const notify_success = (message: any) =>
  //   toast.success(`Message: \n ${message}`, {
  //     position: "top-center",
  //     theme: "colored",
  //   });
  //
  //methods
  // const HandleAddUser = async (event: any) => {
  //   event.preventDefault();
  //   try {
  //     var req = await addUser({
  //       Name: userName,
  //       Email: userEmail,
  //       Password: userPassword,
  //       Role: userRole,
  //       CreatedBy: sessionStorage.get("user_name"),
  //     });
  //     console.log(req);
  //     notify_success(req.data.msg);
  //   } catch (err: any) {
  //     notify(err.response.data || err);
  //     console.log(err);
  //   }
  // };
  //
  return (
    <div className="moduleMainDiv">
      <ToastContainer />
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h2 className="moduleName">Users</h2>
        </div>

        <div className="moduleMngActionDiv">
          {isAdmin ? (
            <Button
              onClick={(event) => {
                // handleOpen();
                // HandleGetSuppliers(event);
                navigate("./");
              }}
            >
              Users
            </Button>
          ) : // <div className="AppCard">Request The Admin for Log Modul</div>
          null}

          {isAdmin ? (
            <Button
              onClick={(event) => {
                // handleOpen();
                // HandleGetSuppliers(event);
                navigate("departments");
              }}
            >
              Departments
            </Button>
          ) : // <div className="AppCard">Request The Admin for Log Modul</div>
          null}

          {isAdmin ? (
            <Button
              onClick={(event) => {
                // handleOpen();
                // HandleGetSuppliers(event);
                navigate("configurations");
              }}
            >
              Configuration
            </Button>
          ) : // <div className="AppCard">Request The Admin for Log Modul</div>
          null}
        </div>
        {/* <Button className="newBtn" onClick={handleOpen}>
          New
        </Button> */}
        {/* <div>
          <label>Filter</label>
          <label>Group</label>
          <label>Favorite</label>
        </div> */}
      </div>
      <div className="userDisplay">
        <Routes>
          <Route index element={<UserTable />}></Route>
          <Route path="/departments" element={<Department />} />
          <Route path="/configurations" element={<UsersConfiguration />} />
        </Routes>
      </div>
      {/* mui model */}
      <div>
        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              New User Form
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <form className="NewUserForm" onSubmit={HandleAddUser}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <input
                        type="text"
                        placeholder="Name"
                        onChange={(event) => setUserName(event.target.value)}
                        required
                      ></input>
                      <input
                        type="email"
                        placeholder="Email"
                        onChange={(event) => setUserEmail(event.target.value)}
                        required
                      ></input>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <input
                      type="text"
                      placeholder="Password"
                      onChange={(event) => setUserPassword(event.target.value)}
                      required
                    ></input>
                    <label>
                      Role:
                      <select
                        name="selectedRole"
                        onChange={(event) => setUserRole(event.target.value)}
                      >
                        <option value=""></option>
                        <option value="Admin">Admin</option>
                        <option value="User">User</option>
                        <option value="User">Research & Development</option>
                        <option value="User">Work Shop</option>
                        <option value="User">Human Resouce</option>
                        <option value="User">Invenory Management</option>
                      </select>
                    </label>
                  </div>
                </div>
                <button className="AddBtn" type="submit">
                  Add
                </button>
              </form>
            </Typography>
          </Box>
        </Modal> */}
      </div>
      {/*  */}
    </div>
  );
};

export default UserMng;
