import React, { useState } from "react";
import "./Profile.css";
//
import { useNavigate } from "react-router-dom";
//
import { localStorage, sessionStorage } from "es-storage";
//
import { ChangePassword } from "../../API/userAPI";
import { Await } from "react-router-dom";
//

const Profile = () => {
  //
  const [UserImage, setUserImage] = useState(sessionStorage.get("user_image"));
  const navigate = useNavigate();
  //

  const handleNavigation = (e: any) => {
    e.preventDefault();
    navigate("/landing/profile_edit", { replace: false });
  };

  return (
    <div className="profile">
      <div className="profileContainer">
        <div className="profilecontent">
          <div className="profilecontent1">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <img className="UserAvaterPreview" src={UserImage} alt="User Avater"></img>
            </div>
          </div>
          <div className="profilecontent2">
            {/* <form
              style={{ display: "flex", flexDirection: "column", width: "40%" }}
            > */}
            <label>
              User ID :
              <input
                type="text"
                value={sessionStorage.get("user_id")}
                placeholder="User ID"
                readOnly
              ></input>
            </label>
            <label>
              User Name :{" "}
              <input
                type="text"
                value={sessionStorage.get("user_name")}
                placeholder="User Name"
                readOnly
              ></input>
            </label>

            <label>
              Work Email :
              <input
                type="text"
                value={sessionStorage.get("user_email")}
                placeholder="Work Email"
                readOnly
              ></input>
            </label>

            <label>
              Work Phone :{" "}
              <input type="text" placeholder="Work Phone" readOnly></input>
            </label>

            <label>
              Adress :{" "}
              <input type="text" placeholder="Work Location" readOnly></input>
            </label>

            <label>
              Role :{" "}
              <input
                type="text"
                placeholder=""
                value={sessionStorage.get("user_role")}
                readOnly
              ></input>
            </label>
            <div>
              <button onClick={(event) => handleNavigation(event)}>
                Edit Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
