import React from "react";
import "./App.css";
//auth
import { useAuthContext } from "./hooks/authcontext";
//
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import { BrowserRouter, Routes, Route } from "react-router-dom";
//pages
import ForgetPassword from "./pages/Authorization/ForgotPassword/ForgotPassword";
import RestPassword from "./pages/Authorization/ResetPassword/ResetPassword";
//import SignUp from "./pages/Authorization/SignUp/SignUp";
import SignIn from "./pages/Authorization/SignIn/SignIn";
import Landing from "./pages/Landing/Landing";
import DocViewer from "./pages/Inventory/MyDocViewer/MyDocViewer";
//
import NoPage from "./pages/NoPage/NoPage";
//

function App() {
  const { authed } = useAuthContext();
  const notify = () => toast("Wow so easy!");

  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Landing2 />}></Route>
          <Route path="/tenantreg" element={<TenantRegistration />}></Route> */}
          {/* <Route path="/login/:tenantId" element={<SignIn />}></Route> */}
          <Route path="/:tenantUUID" element={<SignIn />}></Route>
          <Route
            path="/forgot-password/:tenantUUID"
            element={<ForgetPassword />}
          ></Route>
          <Route
            path="/reset-password/:token"
            element={<RestPassword />}
          ></Route>
          {/* <Route path="/" element={<SignIn />}></Route> */}
          <Route path="/landing/*" element={authed ? <Landing /> : <SignIn />}>
            {/* <Route path="usermng" element={<UserMng />} />
            <Route path="inventory/*" element={<Inventory />} />
            <Route path="profile" element={<Profile />}></Route>
            {/* <Route path="profile_edit" element={<ProfileEdit />} /> */}
            {/* <Route path="document" element={<Documentations />} />
            <Route path="settings/*" element={<Settings />}>
              <Route path="usermng" element={<UserMng />} />
              <Route path="productmng" element={<ProductMng />} />
            </Route> */}
          </Route>
          <Route path="/docviewer" element={<DocViewer />}></Route>
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
